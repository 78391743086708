import { observer } from "mobx-react";
import React from "react";
import EditDialog from "../../components/EditDialog";
import { CartesianGrid, Cell, Line, LineChart, Pie, PieChart, Tooltip, XAxis, YAxis } from "recharts";
import moment from "moment";
import "moment/locale/pt-br";


const COLORS = ['#2D681E', '#ed3109', '#0737F7', ' #e67e22', '#6b29d1'];
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

class StatisticsEdit extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      chartHeight : 0,
      chartWidth  : 0,
      activeIndex : 0,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.onResize)
    this.onResize();
  }

  componentWillUnmount(){
    window.removeEventListener("resize", this.onResize);
  }

  onResize = () => {
    this.setState({
      chartWidth: window.innerWidth * 0.4,
      chartHeight: window.innerHeight * 0.5,
    });
  }

  onQuery = async () => {
    const { store } = this.props;
    const res = await store.get();
    store.edit = res;
  }

  formatAxisDate = (date) => {
    const d = moment(date).locale("pt-br");
    return d.format("MMMM/YYYY");
  }

  onPieEnter = (_, index) => {
    this.setState({
      activeIndex: index,
    });
  };

  render() {
    const { chartHeight, chartWidth } = this.state;
    const { store } = this.props;
    this.item = store?.edit != null ? store.edit : {};

    const paymentTypeTotal = [ 
      { name: "Dinheiro", value: this.item.totalSalesPriceCash }, 
      { name: "Cartão de Crédito", value: this.item.totalSalesPriceCreditCard }, 
      { name: "Cartão de Crédito + Dinheiro", value: this.item.totalSalesPriceCreditCardCash },
      { name: "Cartão de Débito", value: this.item.totalSalesPriceDebitCard },  
      { name: "PIX", value: this.item.totalSalesPricePix }, 
    ];

    return (
      <EditDialog
        title={`Dados de venda de ${this.item.user?.name}`}
        opened={store?.edit != null}
        onCancel={() => (store.edit = null)}
      >
        <div className="rowStyles">
          <div className="columnStyles">
            <p>
              {`Vendedor(a): ${this.item.user?.name}`}
            </p>
            <p>
              {`Ticket médio de vendas: R$ ${!!this.item.averageSalesPrice ? this.item.averageSalesPrice?.toFixed(2)?.replace(".", ",") : "0,00"}`}
            </p>
            <p>
              {`Quantidade de clientes cadastrados no período: ${this.item.customersRegistered}`}
            </p>
            <p>
              {`Carteira de clientes da consultora: ${this.item.customersTotal}`}
            </p>
            <p>
              {`Valor vendido no período: R$ ${!!this.item.totalSalesPrice ? this.item.totalSalesPrice?.toFixed(2)?.replace(".", ",") : "0,00"}`}
            </p>
            <p>
              {`SKUs vendidos no período: ${this.item.skuSales}`}
            </p>
            <p>
              {`Total de recompras no período: ${this.item.totalCustomerRepurchases}`}
            </p>

            <p>
              <h4>
                Métodos de pagamento
              </h4>
              <p>
                {`Compras pagas em dinheiro: ${this.item.totalCashPurchases}`}
              </p>
              <p>
                {`Compras pagas em cartão de crédito: ${this.item.totalCreditCardPurchases}`}
              </p>
              <p>
                {`Compras pagas em cartão de crédito + dinheiro: ${this.item.totalCreditCardCashPurchases}`}
              </p>
              <p>
                {`Compras pagas em cartão de débito: ${this.item.totalDebitCardPurchases}`}
              </p>
              <p>
                {`Compras pagas em PIX: ${this.item.totalPixPurchases}`}
              </p>
              <p>
                {`Compras pagas com link de pagamento: ${this.item.totalPaymentLinkPurchases}`}
              </p>
            </p>
          </div>
          <div className="columnStyles" style={{ marginLeft: 20 }}>
            <p>Histórico de vendas do período</p>
            <LineChart
              width={chartWidth}
              height={chartHeight}
              data={this.item?.salesTotalByDate}
              margin={{ right: 10, left: 10, top: 10 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis 
                type="category" 
                dataKey="date" 
                tickFormatter={this.formatAxisDate}
              />
              <YAxis type="number" dataKey="value" />
              <Tooltip itemStyle={{ color: "black" }} labelStyle={{ color: "black" }}/>
              <Line dataKey="value" fill="#8884d8" dot={false} />
              <Tooltip />
            </LineChart>
            
            { (!!this.item.totalSalesPrice && this.item.totalSalesPrice > 0) &&
              <div>
                <p>Tipos de pagamento no período selecionado</p>
                <div style={styles.row}>
                  <div style={{ backgroundColor: COLORS[0], width: 10, height: 10, marginRight: 10 }} />
                  <h3 style={{ color: COLORS[0] }}>
                    {`${paymentTypeTotal[0].name}: R$${paymentTypeTotal[0].value?.toFixed(2).replace(".", ",") ?? "0,00"}`}
                  </h3>
                </div>
                <div style={styles.row}>
                  <div style={{ backgroundColor: COLORS[1], width: 10, height: 10, marginRight: 10 }} />
                  <h3 style={{ color: COLORS[1] }}>
                  {`${paymentTypeTotal[1].name}: R$${paymentTypeTotal[1].value?.toFixed(2).replace(".", ",") ?? "0,00"}`}
                  </h3>
                </div>
                <div style={styles.row}>
                  <div style={{ backgroundColor: COLORS[2], width: 10, height: 10, marginRight: 10 }} />
                  <h3 style={{ color: COLORS[2] }}>
                  {`${paymentTypeTotal[2].name}: R$${paymentTypeTotal[2].value?.toFixed(2).replace(".", ",") ?? "0,00"}`}
                  </h3>
                </div>
                <div style={styles.row}>
                  <div style={{ backgroundColor: COLORS[3], width: 10, height: 10, marginRight: 10 }} />
                  <h3 style={{ color: COLORS[3] }}>
                  {`${paymentTypeTotal[3].name}: R$${paymentTypeTotal[3].value?.toFixed(2).replace(".", ",") ?? "0,00"}`}
                  </h3>
                </div>
                <div style={styles.row}>
                  <div style={{ backgroundColor: COLORS[4], width: 10, height: 10, marginRight: 10 }} />
                  <h3 style={{ color: COLORS[4] }}>
                  {`${paymentTypeTotal[4].name}: R$${paymentTypeTotal[4].value?.toFixed(2).replace(".", ",") ?? "0,00"}`}
                  </h3>
                </div>
                <PieChart width={400} height={200}>
                  <Pie
                    data={paymentTypeTotal}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                    label={renderCustomizedLabel}
                  >
                    {paymentTypeTotal.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                  </Pie>
                </PieChart>
              </div>
            }
          </div>
        </div>
      </EditDialog>
    );
  }
}

export default observer(StatisticsEdit);

const styles = {
  inputOutline: {
    marginBottom: "20px",
  },
  subHeader: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: 20,
    paddingRight: 20,
  },
  panelsStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
};
