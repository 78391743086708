const PAYMENT_TYPE_LABEL = {
  "CASH": "Dinheiro",
  "CREDIT_CARD": "Cartão de Crédito",
  "DEBIT_CARD": "Cartão de Débito",
  "CREDIT_CARD_CASH": "Dinheiro e Cartão de Crédito",
  "PIX": "PIX",
  "PIX_CASH": "PIX e Dinheiro",
};

const PAYMENT_STATUS_LABEL = {
  "PENDING"                       : "Aguardando pagamento",
  "CONFIRMED"                     : "Pagamento confirmado",
  "RECEIVED"                      : "Pagamento confirmado",
  "RECEIVED_IN_CASH"              : "Pagamento confirmado",
  "OVERDUE"                       : "Pagamento Atrasado",
  "REFUND_REQUESTED"              : "Estorno Solicitado",
  "REFUNDED"                      : "Estornado",
  "CHARGEBACK_REQUESTED"          : "Recebido chargeback",
  "CHARGEBACK_DISPUTE"            : "Em disputa de chargeback",
  "AWAITING_CHARGEBACK_REVERSAL"  : "Disputa vencida",
  "DUNNING_REQUESTED"             : "Em processo de negativação",
  "DUNNING_RECEIVED"              : "Recuperada",
  "AWAITING_RISK_ANALYSIS"        : "Pagamento em análise",
};

export {
  PAYMENT_TYPE_LABEL,
  PAYMENT_STATUS_LABEL,
}