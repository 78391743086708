import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom';

const whitelist = [
  '/login',
];

const IdleTimer = ({ 
  logout = () => {},
}) => {
  let timeout = null; // <- Javascript
  let history = useHistory();

  useEffect(() => {
    let preventReset = false;
    for (const path of whitelist) {
      if (path === history.location.pathname) {
        preventReset = true;
      }
    }
    if (preventReset) {
      return;
    }

     // initiate timeout
     restartAutoReset();

     // listen for mouse events
    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('keypress', onMouseMove);

    // cleanup
    return () => {
      if (timeout) {
        clearTimeout(timeout);
        window.removeEventListener('mousemove', onMouseMove);
        window.removeEventListener('keypress', onMouseMove);
      }
    };
    // eslint-disable-next-line 
  }, [history.location]); 

  const restartAutoReset = () => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      logout();
      history.replace("/login");
    }, 1000 * 3600); // 60 Minutes
  };

  const onMouseMove = () => {
    restartAutoReset();
  };

  return <div />; // must show something on the screem
};

export default IdleTimer;