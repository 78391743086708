import { observer } from "mobx-react";
import React from "react";
import { InputAdornment } from "@material-ui/core";
import {
  Person,
  Email,
  ContactMail,
  Phone,
  VpnKey,
  AccountBalanceWallet,
} from "@material-ui/icons";

import TextField from "../../components/TextField";
import EditDialog from "../../components/EditDialog";
import CheckBox from "../../components/CheckBox";
import SelectField from "../../components/SelectField";

import Stores from "../../stores/Stores";
import UIStore from "../../stores/UIStore";
import { REQUIRED } from "../../utils/Validation";

const store = Stores.load("users");

class UserEdit extends React.Component {
  componentDidMount() {
    store.rules = {
      name: REQUIRED,
      email: REQUIRED,
      maxInstallments: REQUIRED,
      cpf: REQUIRED,
      phone: REQUIRED,
      role: REQUIRED,
    };
  }

  save = () =>
    store
      .save(this.props.onSave)
      .then(() => UIStore.redo(store))
      .catch(UIStore.saveError);

  render() {
    this.item = store.edit != null ? store.edit : {};
    let roles = [
      { label: "Administrador", value: "ADMIN" },
      { label: "Usuário", value: "USER" },
      { label: "Gerenciamento", value: "MANAGER" },
    ];

    return (
      <EditDialog
        title={this.item.id ? "Editar Revendedor" : "Novo Revendedor"}
        opened={store.edit != null}
        onCancel={() => (store.edit = null)}
        onSave={this.save}
      >
        <div className="rowStyles">
          <div className="columnStyles">
            <TextField
              errors={store.errors}
              field="name"
              item={this.item}
              label="Nome"
              style={styles.inputOutline}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Person color="primary" />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              errors={store.errors}
              field="email"
              item={this.item}
              label="E-mail"
              style={styles.inputOutline}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Email color="primary" />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              errors={store.errors}
              field="maxInstallments"
              item={this.item}
              label="Parcelas"
              style={styles.inputOutline}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountBalanceWallet color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="columnStyles column02">
            <TextField
              errors={store.errors}
              field="cpf"
              item={this.item}
              label="CPF"
              style={styles.inputOutline}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ContactMail color="primary" />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              errors={store.errors}
              field="phone"
              item={this.item}
              label="Telefone"
              style={styles.inputOutline}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Phone color="primary" />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              errors={store.errors}
              field="password"
              item={this.item}
              label="Senha"
              style={styles.inputOutline}
              type="password"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <VpnKey color="primary" />
                  </InputAdornment>
                ),
              }}
            />
            <SelectField
              errors={store.errors}
              field="role"
              item={this.item}
              label="&nbsp; Tipo"
              list={roles}
              style={styles.inputOutline}
              variant="outlined"
            />
            <div>
              <CheckBox label="Ativo?" field="enabled" item={this.item} />
            </div>
          </div>
        </div>
      </EditDialog>
    );
  }
}

export default observer(UserEdit);

const styles = {
  inputOutline: {
    marginBottom: "20px",
  },
};
