import { observer } from "mobx-react";
import React from "react";
import { AppBar, InputAdornment, Tab, Tabs } from "@material-ui/core";
import {
  Person,
  Email,
  ContactMail,
  LocationOn,
  Phone,
} from "@material-ui/icons";
import TextField from "../../components/TextField";
import EditDialog from "../../components/EditDialog";
import CheckBox from "../../components/CheckBox";
import Stores from "../../stores/Stores";
import UIStore from "../../stores/UIStore";
import { REQUIRED } from "../../utils/Validation";
import { getCepData } from "../../utils/ViaCep";
import SwipeableViews from "react-swipeable-views";
import { get, jsonParser } from "../../utils/Client";
import { PAYMENT_TYPE_LABEL } from "../../Constants/Constants";

const store = Stores.load("customers");

class CustomerEdit extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      tab         : 0,
      lastPurchase: null,
    };
  }

  componentDidMount() {
    store.rules = {
      name: REQUIRED,
      email: REQUIRED,
      zipcode: REQUIRED,
      cpfCnpj: REQUIRED,
      phone: REQUIRED,
      address: REQUIRED,
      addressNumber: REQUIRED,
      neighborhood: REQUIRED,
    };
  }

  save = () => 
    store
      .save(this.props.onSave)
      .then(() => UIStore.redo(store))
      .catch(UIStore.saveError);

  handleChangeTab = async (event, tab) => {
    this.item = store.edit != null ? store.edit : {};

    if(tab === 0){
      this.setState({ tab });
    } else if (tab === 1) {
      const res = await get(`/payments/customer/last/${this.item.id}`).then(jsonParser);
      console.log(res)
      this.setState({ lastPurchase: res, tab }); 
    }
  };

  renderGeneralScreen = (item) => {

    return(
      <div className="rowStyles">
        <div className="columnStyles">
          <TextField
            errors={store.errors}
            field="name"
            item={this.item}
            label="Nome"
            style={styles.inputOutline}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Person color="primary" />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            errors={store.errors}
            field="email"
            item={this.item}
            label="E-mail"
            style={styles.inputOutline}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email color="primary" />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            errors={store.errors}
            field="cpfCnpj"
            item={this.item}
            label="CPF/CNPJ"
            onBlur={(e) => (this.item.cpfCnpj = e?.target?.value?.replace(/\D/g, "").formatDocument())}
            style={styles.inputOutline}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <ContactMail color="primary" />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            errors={store.errors}
            field="phone"
            item={this.item}
            label="Telefone"
            onBlur={(e) => (this.item.phone = e?.target?.value?.formatPhone())}
            style={styles.inputOutline}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Phone color="primary" />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="columnStyles column02">
          <TextField
            errors={store.errors}
            field="zipcode"
            item={this.item}
            label="CEP"
            onBlur={async (e) => {
              this.item.zipcode = e?.target?.value?.formatCep();
              if(this.item.zipcode.length >= 9){
                try{
                  const res = await getCepData(this.item.zipcode);
                  this.item.address = res.logradouro;
                  this.item.neighborhood = res.bairro;
                } catch(e){
                  console.log(e.message ?? e);
                }
              }
            }}
            style={styles.inputOutline}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocationOn color="primary" />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            errors={store.errors}
            field="address"
            item={this.item}
            label="Endereço"
            style={styles.inputOutline}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocationOn color="primary" />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            errors={store.errors}
            field="addressNumber"
            item={this.item}
            label="Número do endereço"
            style={styles.inputOutline}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocationOn color="primary" />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            errors={store.errors}
            field="neighborhood"
            item={this.item}
            label="Bairro"
            style={styles.inputOutline}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocationOn color="primary" />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            errors={store.errors}
            field="complement"
            item={this.item}
            label="Complemento"
            style={styles.inputOutline}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocationOn color="primary" />
                </InputAdornment>
              ),
            }}
          />
          <div>
            <CheckBox label="Liberado?" field="enabled" item={this.item} />
          </div>
        </div>
      </div>
    )
  }

  renderLastPurchaseScreen = () => {
    const { lastPurchase } = this.state;

    return(
      <div className="rowStyles">
        <div className="columnStyles">
          <h4 style={styles.liTexts}>Dados da última compra:</h4>
          <p style={styles.liTexts}>Ticket ID: {lastPurchase?.id}</p>
          <p style={styles.liTexts}>
            Data da compra: {lastPurchase?.createdDate?.split("T")[0].split("-").reverse().join("/")}
          </p>
        </div>
        <div className="columnStyles column02">
          <h4 style={styles.liTexts}>Pagamentos:</h4>
          { lastPurchase?.payments?.map((p, index) =>
            <div>
              { !!p.value &&
                <p style={styles.liTexts}>{`Valor em ${PAYMENT_TYPE_LABEL[p.billingType]}: R$${p.value?.toFixed(2)}`}</p>
              }
              { !!p.valueInCash &&
                <p style={styles.liTexts}>Valor em Dinheiro: {"R$ " + p.valueInCash?.toFixed(2)}</p>
              }
              <p style={styles.liTexts}>
                Data da pagamento: {!!p.confirmedDate ? p.confirmedDate?.split("T")[0].split("-").reverse().join("/") : ""}
              </p>
            </div>
          )}
        </div>
      </div>
    )
  }

  render() {
    const { tab } = this.state;
    this.item = store.edit != null ? store.edit : {};

    return (
      <EditDialog
        title={this.item.id ? "Editar Revendedor" : "Novo Revendedor"}
        opened={store.edit != null}
        onCancel={() => (store.edit = null)}
        onSave={this.save}
      >
        <AppBar
          position="static"
          style={{
            marginLeft: "-24px",
            marginTop: "-10px",
            width: "calc(100% + 48px)",
          }}
        >
          <Tabs variant="scrollable" value={tab} onChange={this.handleChangeTab}>
            <Tab label="Geral" />
            <Tab label="Última Compra" />
          </Tabs>
        </AppBar>
        <SwipeableViews index={tab}>
          { this.renderGeneralScreen(this.item) }
          { this.renderLastPurchaseScreen(this.item) }
        </SwipeableViews>
        
      </EditDialog>
    );
  }
}

export default observer(CustomerEdit);

const styles = {
  inputOutline: {
    marginBottom: "20px",
  },
};
