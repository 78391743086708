import { action } from "mobx";
import { get, jsonParser } from "../utils/Client";

export const create = (store) => {
  return {
    userSales: [],
    skuSales: [],
    initialDate: null,
    finalDate: null,
  
    get: action(() =>
      get(
        `/statistics/user/${store.selected}?` +
          `${store.initialDate ? "&initialDate=" + store.initialDate : ""}` +
          `${store.finalDate ? "&finalDate=" + store.finalDate : ""}`
      )
      .then(jsonParser)
      .catch(e => console.log(e.message ?? e))
    ),

    query: action(() =>
      get(
        `/statistics/user-sales?` +
          `${store.initialDate ? "&initialDate=" + store.initialDate : ""}` +
          `${store.finalDate ? "&finalDate=" + store.finalDate : ""}` +
          `&sortByName=${store.sort.column === "name"}` +
          `&order=${store.sort.ascending ? "ASC" : "DESC"}`
      )
      .then(jsonParser)
      .then((data) => {
        store.userSales = data;
        store.count = data.length ?? 0;
      })
      .catch(e => console.log(e.message ?? e))
    ),

    querySkus: action(() =>
      get(
        `/statistics/sku-sales?` +
          `${store.initialDate ? "&initialDate=" + store.initialDate : ""}` +
          `${store.finalDate ? "&finalDate=" + store.finalDate : ""}` +
          `&sortByName=${store.sort.column === "name"}` +
          `&order=${store.sort.ascending ? "ASC" : "DESC"}`
      )
      .then(jsonParser)
      .then((data) => {
        store.skuSales = data;
        store.count = data.length ?? 0;
      })
      .catch(e => console.log(e.message ?? e))
    ),
  };
};
