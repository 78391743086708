import { observer } from "mobx-react";
import moment from "moment";
import React from "react";
import EditDialog from "../../components/EditDialog";
import Stores from "../../stores/Stores";
import { Button } from "@material-ui/core";
import { post } from "../../utils/Client";


const store = Stores.load("paymentHistory", "createdDate");
const PAYMENT_STATUS = {
  CONFIRMED: "Pagamento confirmado",
  RECEIVED: "Pagamento confirmado",
  PENDING: "Aguardando aprovação",
  REFUNDED: "Pagamento estornado",
  OVERDUE: "Pagamento vencido",
};

class PaymentEdit extends React.Component {
  state = {
    isSubmitting: true,
  };

  cancelPurchase = async () => {
    try{
      await post(`/paymentHistory/cancel/${this.item.id}`);
      store.edit = null;
      store.query();
    } catch(e){
      console.log(e.message ?? e);
    } finally{
      this.setState({ isSubmitting: false });
    }
  }

  render() {
    this.item = store.edit != null ? store.edit : {};

    let paymentType = "";
    let canCancel = true;
    if(!!this.item?.payments){
      const payment = this.item.payments[0] ?? {};
      paymentType = (payment?.billingType === "CREDIT_CARD" && !!payment?.valueInCash)
      ? "Cartão de Crédito e Dinheiro"
      : (payment?.billingType === "PIX" && !!payment?.valueInCash)
      ? "PIX e Dinheiro"
      : payment?.billingType === "PIX"
      ? "PIX"
      : payment?.billingType === "CREDIT_CARD"
      ? "Cartão de Crédito"
      : payment?.billingType === "CASH"
      ? "Dinheiro"
      : payment?.billingType === "DEBIT_CARD"
      ? "Cartão de Débito"
      : payment?.billingType === "PAYMENT_LINK"
      ? "Link de Pagamento"
      : "";
      const filtered = this.item.payments.filter(p => p.status === "REFUNDED" || p.billingType === "CASH");
      canCancel = filtered.length === this.item.installmentCount;
    }

    return (
      <EditDialog
        title="Editar Status do Pagamento"
        opened={store.edit != null}
        onCancel={() => (store.edit = null)}
      >
        <div className="rowStyles">
          <div className="columnStyles">
            <h4 style={styles.liTexts}>
              {`Ticket:  #${this.item?.ticket}`}
            </h4>
            <h4 style={styles.liTexts}>
              {`Revendedor(a):  ${this.item?.user?.name}`}
            </h4>
            <h4 style={styles.liTexts}>
              {`Cliente:  ${this.item?.customer?.name}`}
            </h4>
            <h4 style={styles.liTexts}>
              {`Pagamento:  ${paymentType}`}
            </h4>
            <h4 style={styles.liTexts}>
              {`Total:  ${this.item?.total?.formatMoney()}`}
            </h4>

            { (canCancel && this.item?.enabled) &&
              <Button
                onClick={() => this.setState({ isSubmitting: false }, this.cancelPurchase)} 
                key="cancel"
                style={{ marginTop: 20 }}
              >
                Cancelar compra
              </Button>
            }
          </div>
          <div className="columnStyles">
            <h4 style={{ ...styles.liTexts }}>SKUs:</h4>
            { this.item?.skus?.map((s, index) =>
              <p key={index} style={styles.liTexts}>{`Código: ${s?.code}`}</p>
            )}
          </div>
          <div className="columnStyles">
            { this.item?.installmentCount > 1 &&
              <h4 style={styles.liTexts}>Parcelas</h4>
            }
            {this.item?.payments?.map(p =>
              <div style={{ marginTop: 20 }}>
                { this.item.installmentCount > 1 &&
                  <div>
                    <h4 style={styles.liTexts}>
                      {`Pagamento ${p.installmentNumber ?? 1}`}
                    </h4>
                    <h4 style={styles.liTexts}>
                      {`Valor da parcela: ${(p.value + p.valueInCash).formatMoney()}`}
                    </h4>
                    <h4 style={styles.liTexts}>
                      {`Data de pagamento ${moment(p.createdDate).format("DD/MM/YYYY")}`}
                    </h4>
                  </div>  
                }
                <h4 style={styles.liTexts}>
                  {PAYMENT_STATUS[p.status] ?? p.status}
                </h4>
                { !!p.invoiceUrl &&
                  <a href={p.invoiceUrl} target="_BLANK" rel="noopener noreferrer">
                    <h4 style={styles.liTexts}>Comprovante de pagamento do ASAAS</h4>
                  </a>
                }
              </div>
            )}
          </div>
        </div>
      </EditDialog>
    );
  }
}

export default observer(PaymentEdit);

const styles = {
  liTexts: {
    margin: 0,
  },
  btn: {

  },
};
