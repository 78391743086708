import { observer } from "mobx-react";
import moment from "moment";
import React from "react";
import CheckBox from "../../components/CheckBox";
import EditDialog from "../../components/EditDialog";
import Stores from "../../stores/Stores";
import UIStore from "../../stores/UIStore";


const store = Stores.load("paymentErrors", "createdDate");

class PaymentEdit extends React.Component {
  save = () =>
    store
      .save(this.props.onSave)
      .then(() => UIStore.redo(store))
      .catch(UIStore.saveError);

  render() {
    this.item = store.edit != null ? store.edit : {};

    return (
      <EditDialog
        title="Editar Status do Pagamento"
        opened={store.edit != null}
        onCancel={() => (store.edit = null)}
        onSave={this.save}
      >
        <div className="rowStyles">
          <div className="columnStyles">
            <p style={styles.liTexts}>
              {`Revendedor(a): ${this.item?.user?.name}`}
            </p>
            <p style={styles.liTexts}>
              {`Cliente: ${this.item?.customer?.name}`}
            </p>
            <p style={styles.liTexts}>
              {`CPF/CNPJ do cliente: ${this.item?.customer?.cpfCnpj?.formatDocument()}`}
            </p>
            <p style={styles.liTexts}>
              {`ID do cliente ASAAS: ${this.item?.customer?.asaasCustomerId}`}
            </p>
            <p style={styles.liTexts}>
              {`Data: ${moment(this.item?.createdDate).format("DD/MM/YYYY HH:mm:ss")}`}
            </p>
            <p style={styles.liTexts}>
              {`Erro: ${this.item?.errorDescription}`}
            </p>
            <div>
              <CheckBox label="Liberado?" field="enabled" item={this.item} />
            </div>
          </div>
        </div>
      </EditDialog>
    );
  }
}

export default observer(PaymentEdit);

const styles = {
  liTexts: {
    margin: 0,
  },
};
