import { createMuiTheme } from "@material-ui/core/styles";
import { deepOrange } from "@material-ui/core/colors";

//const tintColor = { light: '#59d5df', main: '#00A3AD', dark: '#00747e' };
const tintColor = { light: '#59d5df', main: '#72D1FD', dark: '#2BBBFD' };

const defaultTheme = createMuiTheme({
  palette: {
    primary: tintColor,
    secondary: deepOrange
  }
});

const theme = createMuiTheme({
  palette: {
    primary: tintColor,
    secondary: deepOrange
  },
  overrides: {
    MuiToolbar: {
      root: {
        color: defaultTheme.palette.common.black,
        backgroundColor: defaultTheme.palette.common.white,
        borderTop: `5px solid ${defaultTheme.palette.primary.main}`
      }
    },
    MuiChip: {
      root: {
        borderRadius: "3px"
      }
    },
    MuiAppBar: {
      root: {
        boxShadow:
          "0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.08), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)"
      }
    }
  }
});

export default theme;
