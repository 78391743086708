import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { MenuItem, Menu } from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";

class SimpleSelectPanel extends React.Component {
  state = { anchorEl: null };

  handleOpen = (event) => this.setState({ anchorEl: event.currentTarget });

  handleSelect = (o) => {
    let { store, entity } = this.props;
    store[entity] = o;
    store.query();
    this.setState({ anchorEl: null });
  };

  render() {
    let { onSelect, options, label, selectedLabel } = this.props;
    let { anchorEl } = this.state;

    return (
      <>
        <div
          style={{
            width: "100%",
            margin: "2px 10px 0 0",
            lineHeight: "35px",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
          }}
          onClick={this.handleOpen}
        >
          <strong>{label}:</strong> <KeyboardArrowDown style={{ marginLeft: "5px" }} />{" "}
          {selectedLabel}
        </div>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => this.setState({ anchorEl: null })}
        >
          {options.map((o, i) => (
            <MenuItem 
              key={`k${i}`} 
              onClick={() => {
                onSelect(o.value);
                this.setState({ anchorEl: null });
              }}>
              {o.label}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  }
}

SimpleSelectPanel.propTypes = {
  label: PropTypes.string,
  onSelect: PropTypes.func,
  options: PropTypes.array,
  selectedLabel: PropTypes.string,
}

export default observer(SimpleSelectPanel);
