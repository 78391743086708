import React from "react";
import { observer } from "mobx-react";
import { Checkbox } from "@material-ui/core";
import moment from "moment";
import List from "../../components/List";
import Edit from "./Edit";
import Stores from "../../stores/Stores";
import UIStore from "../../stores/UIStore";
import SortPanel from "../../components/SortPanel";
import SelectPanel from "../../components/SelectPanel";
import DateRange from "../../components/DateRange";
import AuthenticationStore from "../../stores/AuthenticationStore";
import { PAYMENT_TYPE_LABEL } from "../../Constants/Constants";


const store = Stores.load("paymentErrors", "createdDate");
const usersStore = Stores.create("users", "name");
const customersStore = Stores.create("customers", "name");

class PaymentErrors extends React.Component {
  componentDidMount() {
    usersStore.size = 1000;
    usersStore.query();
    customersStore.size = 1000;
    customersStore.query();
    store.sort = { column: "createdDate", ascending: false };
    UIStore.init("Erros de Pagamento", store);
  }

  check = () =>
    store
      .check()
      .then(() => store.query())
      .then(() => UIStore.redo(store))
      .catch(UIStore.saveError);

  handleDelete = () => {
    let count = store.selected.length;

    UIStore.showConfirmDialog(
      `Deseja remover ${count} mensagens de erro?`,
      "Remover mensagens de erro"
    ).then((b) => {
      if (b) {
        store
          .delete()
          .then(() => store.query())
          .catch(() => alert("Erro!"));
      }
    });
  };

  handleFilter = () => {
    store.page = 0;
    store.searchAll = !store.searchAll;
    store.query();
  };

  render() {
    let toggleFilters = (
      <ul className="filters-list">
        <li>
          <div
            className={!store.searchAll ? "activated" : ""}
            onClick={this.handleFilter}
          >
            Liberados
          </div>
        </li>
        <li>
          <div
            className={store.searchAll ? "activated" : ""}
            onClick={this.handleFilter}
          >
            Todos
          </div>
        </li>
      </ul>
    );

    let canEdit;
    if (AuthenticationStore.me.role === "MANAGER") {
      canEdit = false;
    } else {
      canEdit = true;
    }

    let data = store.list.map((i) => ({
      icon: (
        <Checkbox
          color="primary"
          checked={i.checked}
          onChange={(event) => {
            store.selected = [i];
            this.check();
          }}
          style={styles.checkBox}
        />
      ),
      checked: i.checked,
      item: i,
      important: null,
      title: `Revendedor(a): ${i?.user?.name}`,
      content: (
        <>
          {`Cliente: ${i?.customer?.name}`}
          <br />
          {moment(i.createdDate).format("DD/MM/YYYY HH:mm")}
        </>
      ),
    }));

    return (
      <>
        <List
          store={store}
          data={data}
          status={store.list.map((i) => ({
            id: i.id,
            label:
              !!i.billingType
                ? PAYMENT_TYPE_LABEL[i.billingType]
                : "",
            class:
              !!i.billingType
                ? "approved-status"
                : "",
          }))}
          leftPanel={
            <>
              { toggleFilters }
              <SelectPanel
                options={usersStore.list}
                store={store}
                entity="user"
                label="Revendedor(a)"
                getLabel={(u) => (u ? u.name : "Todos")}
              />
              <SelectPanel
                options={customersStore.list}
                store={store}
                entity="customer"
                label="Cliente"
                getLabel={(c) => (c ? c.name : "Todos")}
              />
              <DateRange label="Período" store={store} />
            </>
          }
          rightPanel={
            <>
              <SortPanel
                store={store}
                filters={{
                  "user.name": "Nome do Revendedor",
                  value: "Valor",
                  createdDate: "Data de Realização",
                }}
              />
            </>
          }
          onEdit={canEdit ? () => store.open() : null}
          onDelete={canEdit ? () => this.handleDelete() : null}
        />
        <Edit onSave={() => store.query()} />
      </>
    );
  }
}

export default observer(PaymentErrors);

const styles = {
  checkBox: {
    marginRight: 5,
    padding: 3,
    alignSelf: "flex-start",
  },
};
