import { extendObservable, action } from "mobx";

import { get, form, post, jsonParser } from "../utils/Client";
import { history } from "../";

class AuthenticationStore {
  constructor() {
    extendObservable(this, {
      isAuthenticated: false,

      me: {},

      schemas: [],

      testAuthenticated: action(() =>
        get("/me")
          .then((res) => {
            if (res.status === 401 || res.status === 403) {
              this.isAuthenticated = false;
              sessionStorage.apiToken = null;
              history.push("/login");
              return Promise.reject();
            } else {
              this.isAuthenticated = true;
              return res;
            }
          })
          .then(jsonParser)
          .then((data) => {
            this.me = data;
          })
      ),

      login: action((email, password) =>
        form("/authenticate", {
          body: { email, password },
        })
          .then((res) => {
            if (res.status === 403) {
              this.isAuthenticated = false;
              sessionStorage.apiToken = null;
              this.user = {};
              return Promise.reject("Usuário ou senha inválidos.");
            } else {
              this.isAuthenticated = true;
              return res.json();
            }
          })
          .then((data) => {
            this.me = data.user;
            sessionStorage.apiToken = data.token;
          })
      ),

      register: action((name, email, password, cpf) =>
        post("/register", {
          body: { name, email, password, cpf },
          credentials: "omit",
        })
      ),

      logout: action(() => {
        //sessionStorage.clear();
        sessionStorage.apiToken = null;
        this.isAuthenticated = false;
        this.user = {};
      }),

      hasRole: action(
        (role) =>
          this.me.perfil &&
          this.me.perfil.autorizacoes.filter((a) => a.nome === role).length > 0
      ),
    });
  }
}

export default new AuthenticationStore();
