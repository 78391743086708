import React from "react";
import { observer } from "mobx-react";
import { Checkbox } from "@material-ui/core";
import moment from "moment";
import List from "../../components/List";
import Edit from "./Edit";
import Stores from "../../stores/Stores";
import UIStore from "../../stores/UIStore";
import SortPanel from "../../components/SortPanel";
import SelectPanel from "../../components/SelectPanel";
import DateRange from "../../components/DateRange";
import AuthenticationStore from "../../stores/AuthenticationStore";
import SearchField from "../../components/SearchField";
import SimpleSelectPanel from "../../components/SimpleSelectPanel";
import { PAYMENT_STATUS_LABEL } from "../../Constants/Constants";


const store = Stores.load("paymentHistory", "user.name");
const usersStore = Stores.create("users", "name");
const customersStore = Stores.create("customers", "name");

class Payments extends React.Component {

  componentDidMount() {
    usersStore.size = 1000;
    usersStore.query();
    customersStore.size = 1000;
    customersStore.query();
    store.sort = { column: "createdDate", ascending: false };
    UIStore.init("Pagamentos", store);
  }

  handleDelete = () => {
    let count = store.selected.length;

    UIStore.showConfirmDialog(
      `Deseja remover ${count} pagamentos?`,
      "Remover Pagamentos"
    ).then((b) => {
      if (b) {
        store
          .delete()
          .then(() => store.query())
          .catch(() => alert("Erro!"));
      }
    });
  };

  check = () =>
    store
      .check()
      .then(() => store.query())
      .then(() => UIStore.redo(store))
      .catch(UIStore.saveError);

  onSelectReport = async (value) => {
    const result = value === 0 ? await store.reportTsv() : await store.userReportTsv();
    const blob = await result.blob();
    const href = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.href = href;
    tempLink.setAttribute('download', value === 0 ? "Relatorio.tsv" : "Relatorio_vendedoras.tsv");
    tempLink.click();
  }

  handleFilter = () => {
    store.page = 0;
    store.searchAll = !store.searchAll;
    store.query();
  };

  render() {
    let toggleFilters = (
      <ul className="filters-list">
        <li>
          <div
            className={!store.searchAll ? "activated" : ""}
            onClick={this.handleFilter}
          >
            Liberados
          </div>
        </li>
        <li>
          <div
            className={store.searchAll ? "activated" : ""}
            onClick={this.handleFilter}
          >
            Todos
          </div>
        </li>
      </ul>
    );

    let canEdit;
    if (AuthenticationStore.me.role === "MANAGER") {
      canEdit = false;
    } else {
      canEdit = true;
    }

    let data = store.list.map((i) => ({
      icon: (
        <Checkbox
          color="primary"
          checked={i.checked}
          onChange={(event) => {
            store.selected = [i];
            this.check();
          }}
          style={styles.checkBox}
        />
      ),
      checked: i.checked,
      item: i,
      important: null,
      title: <div>{`Total: ${i?.total.formatMoney()}`}</div>,
      content: (
        <div style={styles.detailsContainer}>
          <div style={{ marginRight: 30, width: 200 }}>
            {`Ticket: #${i.ticket}`}
            <br />
            {`Revendedor(a): ${i.user.name}`}
            <br />
            {`Cliente: ${i.customer.name}`}
            <br />
            {moment(i.createdDate).format("DD/MM/YYYY HH:mm")}
          </div>
          <div >
            <div style={{ fontWeight: "bold" }}>
              {`${i.installmentCount > 1 ? i.installmentCount + " parcelas" : "À vista"}`}
            </div>
            { i.installmentCount < 2 ?
              <div>
                {PAYMENT_STATUS_LABEL[i.payments[0]?.status]}
              </div>
              :
              i.payments.sort((a, b) => a.installmentNumber - b.installmentNumber).map((p, index) =>
              <div key={index}>
                {`${p.description} - ${PAYMENT_STATUS_LABEL[p.status]}`}
              </div>
            )}
          </div>
        </div>
      ),
    }));

    return (
      <>
        <List
          store={store}
          data={data}
          status={store.list.map((i) => {
            const payment = !!i.payments ? i.payments[0] : {};
            const hasRefund = !!(i.payments?.find(p => p.status === "REFUNDED"));

            return {
              id: i.id,
              label:
                (payment?.billingType === "CREDIT_CARD" && !!payment?.valueInCash)
                  ? "Cartão de Crédito e Dinheiro"
                  : (payment?.billingType === "PIX" && !!payment?.valueInCash)
                  ? "PIX e Dinheiro"
                  : payment?.billingType === "PIX"
                  ? "PIX"
                  : payment?.billingType === "CREDIT_CARD"
                  ? "Cartão de Crédito"
                  : payment?.billingType === "CASH"
                  ? "Dinheiro"
                  : payment?.billingType === "DEBIT_CARD"
                  ? "Cartão de Débito"
                  : payment?.billingType === "PAYMENT_LINK"
                  ? "Link de Pagamento"
                  : "",
              class: (!i.enabled || hasRefund) ? "canceled-status" : "approved-status",
              label2: 
                !i.enabled 
                  ? "Cancelado" 
                  : hasRefund 
                  ? "Extornado"
                  : "",
              class2: (!i.enabled || hasRefund) ? "canceled-status" : "",
            }
          })}
          leftPanel={
            <>
              <div>
                { toggleFilters }
                <SelectPanel
                  options={usersStore.list}
                  store={store}
                  entity="user"
                  label="Revendedor(a)"
                  getLabel={(u) => (u ? u.name : "Todos")}
                />
                <SelectPanel
                  options={customersStore.list}
                  store={store}
                  entity="customer"
                  label="Cliente"
                  getLabel={(u) => (u ? u.name : "Todos")}
                />
                <DateRange label="Período" store={store} />
              </div>
            </>
          }
          rightPanel={
            <>
              <SearchField store={store} />
              <div>  
                <SimpleSelectPanel
                  label="Relatórios"
                  onSelect={this.onSelectReport}
                  options={[{ label: "Vendas", value: 0 }, { label: "Vendedoras", value: 1 }]}
                  selectedLabel={""}
                />
                <SortPanel
                  store={store}
                  filters={{
                    "user.name": "Nome do Revendedor",
                    total: "Valor",
                    createdDate: "Data",
                    ticket: "Ticket"
                  }}
                />
              </div>
            </>
          }
          onEdit={canEdit ? () => store.open() : null}
          onDelete={canEdit ? () => this.handleDelete() : null}
        />
        <Edit />
      </>
    );
  }
}

export default observer(Payments);

const styles = {
  checkBox: {
    marginRight: 5,
    padding: 3,
    alignSelf: "flex-start",
  },
  detailsContainer: {
    display: "flex",
    justifyContent: "flex-start",
    flex: 1,
  },
};
