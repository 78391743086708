import React from "react";
import { observer } from "mobx-react";
import UIStore from "../../stores/UIStore";
import { Tab, Tabs } from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import { Bar, BarChart, LabelList, Tooltip, XAxis, YAxis } from "recharts";
import DateRange from "../../components/DateRange";
import Stores from "../../stores/Stores";
import SortPanel from "../../components/SortPanel";
import Edit from "./Edit";
import AuthenticationStore from "../../stores/AuthenticationStore";
import moment from "moment";


const DATES = [
  { 
    label: "7 dias", 
    initialDate: moment().add(-7, "days").format("YYYY-MM-DD"), 
    finalDate: moment().format("YYYY-MM-DD") 
  },
  { 
    label: "15 dias", 
    initialDate: moment().add(-15, "days").format("YYYY-MM-DD"), 
    finalDate: moment().format("YYYY-MM-DD") 
  },
  { 
    label: "30 dias", 
    initialDate: moment().add(-30, "days").format("YYYY-MM-DD"),
    finalDate: moment().format("YYYY-MM-DD")
  },
  { 
    label: "Todos", 
    initialDate: null,
    finalDate: null
  },
];
const statisticsStore = Stores.load("statistics");
statisticsStore.initialDate = DATES[0].initialDate;

class Dashboard extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      chartHeight : 0,
      chartWidth  : 0,
      tab         : 0,
    }
    
    window.addEventListener("resize", this.onResize);
    UIStore.init("Dashboard", statisticsStore);
    statisticsStore.query().then(() => this.onResize());
  }

  componentWillUnmount(){
    window.removeEventListener("resize", this.onResize);
  }

  onResize = () => {
    this.setState({
      chartWidth: window.innerWidth * 0.75,
      chartHeight:  (statisticsStore.count * 18) + 100,
    });
  }

  handleChangeTab = (event, tab) => {
    this.setState({ loading: true, tab }, async () => {
      if(tab === 0){
        await statisticsStore.query();
      } else if(tab === 1){
        await statisticsStore.querySkus();
      }
      this.onResize();
    });
  };

  onClickUser = async (data, index) => {
    const user = statisticsStore.userSales[index];
    statisticsStore.selected = user.targetId;
    const res = await statisticsStore.get();
    statisticsStore.edit = res;
  }

  customTooltip = () => {
    return(
      <div>
        <p>teste</p>
      </div>
    )
  }

  renderUserSalesTab = () => {
    const { chartHeight, chartWidth } = this.state;

    return(
      <div>
        <BarChart
          layout="vertical"
          width={chartWidth}
          height={chartHeight}
          data={statisticsStore.userSales}
          margin={{ right: 100, left: 40, top: 40 }}
          
        >
          <XAxis type="number" />
          <YAxis 
            type="category" 
            dataKey="name" 
            onClick={this.onClickUser}
            cursor="pointer"
            interval={0}
            tick={{ fontSize: 14 }}
            width={200}
            tickFormatter={(v, index) => v.length > 20 ? v.slice(0, 20) + "..." : v}
          />
          <Tooltip itemStyle={{ color: "black" }} labelStyle={{ color: "black" }}/>
          <Bar 
            dataKey="value" 
            fill="#8884d8"
            onClick={this.onClickUser}
            cursor="pointer"
          >
            <LabelList 
              dataKey="value" 
              formatter={v => `R$${v.toFixed(2).replace(".", ",")}`}
              position="right" 
              style={{ fontSize: 14 }}
            />
          </Bar>
          <Tooltip />
        </BarChart>
      </div>
    )
  }

  renderSkuSalesTab = () => {
    const { chartHeight, chartWidth } = this.state;

    return(
      <div>
        <BarChart
          layout="vertical"
          width={chartWidth}
          height={chartHeight}
          data={statisticsStore.skuSales}
          margin={{ right: 100, left: 40, top: 40 }}
        >
          <XAxis type="number" />
          <YAxis 
            type="category" 
            dataKey="name" 
            interval={0}
            cursor="pointer"
            onClick={this.onClickUser}
            tick={{ fontSize: 14 }}
            width={200}
            tickFormatter={(v, index) => v.length > 20 ? v.slice(0, 20) + "..." : v}
          />
          <Tooltip itemStyle={{ color: "black" }} labelStyle={{ color: "black" }}/>
          <Bar 
            dataKey="value" 
            fill="#8884d8"
            onClick={this.onClickUser}
            cursor="pointer"
          >
            <LabelList 
              dataKey="value" 
              position="right" 
              style={{ fontSize: 14 }}
            />
          </Bar>
          <Tooltip />
        </BarChart>
      </div>
    )
  }

  render() {
    const { tab } = this.state;


    if(AuthenticationStore.me.role !== "ADMIN" && AuthenticationStore.me.role !== "MANAGER"){
      return(
        <div className="holder">
          Bem vindo ao administrativo Tiana!
        </div>
      )
    }

    return (
      <div className="holder" style={styles.container}>
        <div style={styles.subHeader} className="subHeader">
          <div style={styles.panelsStyle}>
            <SortPanel
              onQuery={tab === 0 ? statisticsStore.query : statisticsStore.querySkus}
              store={statisticsStore}
              filters={{
                name: "Nome",
                total: "Valor",
              }}
            />
          </div>
          <div style={styles.panelsStyle}>
            <DateRange 
              customDates={DATES}
              initialLabel={DATES[0].label}
              label="Período" 
              store={statisticsStore} 
              onQuery={tab === 0 ? statisticsStore.query : statisticsStore.querySkus}
            />
          </div>
        </div>
        <Tabs
          onChange={this.handleChangeTab}
          variant="scrollable" 
          value={tab}
          style={{ color: "#333" }} 
        >
          <Tab
            label="Desempenho vendas de consultora ativas no periodo (inclui valores de pagamento pendentes)" 
            wrapped={true}
          />
          <Tab
            label="Vendas de SKUs no período" 
            wrapped={true}
          />
        </Tabs>
        <SwipeableViews index={tab}>
          { this.renderUserSalesTab() }
          { this.renderSkuSalesTab() }
        </SwipeableViews>
        <Edit 
          store={statisticsStore}
        />
      </div>
    );
  }
}

export default observer(Dashboard);

const styles = {
  container               : {
    paddingLeft               : 10,
    paddingTop                : 10,
    width                     : "80%",
  },
  subHeader: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  panelsStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
};