import React from "react";
import { observer } from "mobx-react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import CssBaseline from "@material-ui/core/CssBaseline";

import MainBar from "./MainBar";
import Root from "./Root";
import theme from "../components/Theme";

import AuthenticationStore from "../stores/AuthenticationStore";
import UIStore from "../stores/UIStore";

import "./App.css";
import { Link } from "@material-ui/core";
import IdleTimer from "../components/IdleTimer";

class App extends React.Component {
  state = { go: false, open: false };

  componentDidMount() {
    AuthenticationStore.testAuthenticated().then(() =>
      this.setState({ open: AuthenticationStore.isAuthenticated, go: true })
    );
    let width = this.props.width;
    UIStore.asideOpened = width === "lg" || width === "xl";
  }

  handleClose = () => this.setState({ open: false, go: true });

  render() {
    return (
      <CssBaseline>
        <MuiThemeProvider theme={theme}>
          <>
            <MainBar />
            {this.state.go && (
              <Root asideOpen={UIStore.asideOpened || UIStore.forceAside}>
                {this.props.children}
              </Root>
            )}
            <div style={divFooter} className="divFooter">
              <Link
                href="http://mystra.com.br/"
                target="_blank"
                color="inherit"
                style={{ display: "inline-block" }}
              >
                <div style={footerCopy}>
                  &copy; 2020 Mystra Tecnologia
                </div>
              </Link>
            </div>
            <IdleTimer logout={AuthenticationStore.logout} />
          </>
        </MuiThemeProvider>
      </CssBaseline>
    );
  }
}

export default withWidth()(observer(App));

// Footer
const divFooter = {
  padding: "20px 0 60px 0",
  marginTop: -70,
  textAlign: "center",
  fontSize: 16,
}

const footerCopy = {
  color: "#999",
  fontSize: "14px",
  fontWeight: "bold",
}

