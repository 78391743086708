import React from "react";
import { observer } from "mobx-react";
import DateFnsUtils from '@date-io/date-fns';
import moment from "moment"
import {
  DatePicker, 
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Menu,
  MenuItem, 
} from "@material-ui/core";
import TuneIcon from "@material-ui/icons/Tune";


const styles = {
  container: {
    display: "flex",
    justifyContent: "space-between",
  }
};


class DateRange extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      label: !!props.initialLabel ? props.initialLabel : "Todos",
      open: false,
      anchorEl: null,
      initialDate: moment().format("YYYY-MM-DD"),
      finalDate: moment().format("YYYY-MM-DD"),
    };
  }

  handleInitial = date => {
    let { finalDate } = this.state;
    this.setState({initialDate: date});
    if(Date.parse(date) > Date.parse(finalDate)) {
      this.setState({finalDate: date});
    }
  };

  handleFinal = date => {
    let { initialDate } = this.state;
    this.setState({finalDate: date});
    if(Date.parse(date) < Date.parse(initialDate)) {
      this.setState({initialDate: date});
    }
  };

  handleDialog = store => {
    let { initialDate, finalDate } = this.state;
    store.initialDate = moment(initialDate).format("YYYY-MM-DD");    
    store.finalDate = moment(finalDate).format("YYYY-MM-DD");
    store.query();
    this.setState({ open: !this.state.open });
    this.setState({ anchorEl: null });
    this.setState({ label: "De: " + moment(store.initialDate).format("DD/MM/YYYY") + " Até: " + moment(store.finalDate).format("DD/MM/YYYY")});
  };

  handleOpen = (event) => this.setState({ anchorEl: event.currentTarget });

  handleOpenDialog = () => {
    this.setState({ open: !this.state.open });
  };

  handleSelect = (store, o) => {
    const { onQuery, onSelectDate } = this.props;

    store.initialDate = o.initialDate;
    store.finalDate = o.finalDate;
    this.setState({ anchorEl: null });
    this.setState({ label: o.label });
    if(!!onSelectDate){
      onSelectDate(o);
    }
    if(!!onQuery){
      onQuery();
    } else{
      store.query();
    }
  };

  handleClose = () => {
    this.setState({
      open: !this.state.open,
    })
  }

  render() {
    let { store, customDates } = this.props;
    let { label, open, initialDate, finalDate, anchorEl } = this.state;
    let dates = !!customDates ? customDates : [
      { 
        label: "Todos", 
        initialDate: null, 
        finalDate: null 
      },
      { 
        label: "Essa semana", 
        initialDate: moment().startOf('week').format("YYYY-MM-DD") , 
        finalDate: moment().format("YYYY-MM-DD") 
      },
      { 
        label: "Este mês", 
        initialDate: moment().startOf('month').format("YYYY-MM-DD") , 
        finalDate: moment().format("YYYY-MM-DD") 
      },
      { 
        label: "Semana passada", 
        initialDate: moment().subtract(7, 'days').startOf('week').format("YYYY-MM-DD") , 
        finalDate: moment().subtract(7, 'days').endOf('week').format("YYYY-MM-DD")
      },
      { 
        label: "Mês passado", 
        initialDate: moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD") , 
        finalDate: moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD")
      },
    ];

    return (
      <>
      <div
          style={{
            width: "100%",
            margin: "2px 10px 0 0",
            lineHeight: "35px",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            whiteSpace: "nowrap",
          }}
          onClick={this.handleOpen}
        >
        <strong>Período:</strong>
        <TuneIcon style={{ marginLeft: "5px" }} />{" "}
        {label}
      </div>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => this.setState({ anchorEl: null })}
      >
        {dates.map((o, i) => (
          <MenuItem key={`k${i}`} onClick={() => this.handleSelect(store, o)}>
            {o['label']}
          </MenuItem>
        ))}
        <MenuItem key="k-5"
        >
          <div>
            <div onClick={() => this.handleOpenDialog()}>
              <span>
                {"Intervalo..."}
              </span>
            </div>
            <Dialog
              maxWidth={false}
              disableBackdropClick
              disableEscapeKeyDown
              open={open}
              onClose={() => this.handleClose()}
            >
              <DialogTitle>Período</DialogTitle>
              <DialogContent>
                <form>
                  <FormControl>
                    <div style={styles.container}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          margin="normal"
                          variant="static"
                          label="Data inicial"
                          format="yyyy-MM-dd"
                          value={initialDate}
                          onChange={date => this.handleInitial(date)}
                        />
                        <DatePicker
                          margin="normal"
                          variant="static"
                          label="Data final"
                          format="yyyy-MM-dd"
                          value={finalDate}
                          onChange={date => this.handleFinal(date)}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </FormControl>
                </form>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.handleClose()} color="default">
                  Cancelar
                </Button>
                <Button onClick={() => this.handleDialog(store)} color="primary">
                  Filtrar
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </MenuItem>
      </Menu>
      </>
    );
  }
}


export default observer(DateRange);