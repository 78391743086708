import { action } from "mobx";
import { get, jsonParser, put } from "../utils/Client";

export const create = (store) => {
  return {
    user: null,
    customer: null,
    initialDate: null,
    finalDate: null,

    reportTsv: action(() => get(
        `/paymentHistory/report/tsv?query=${store.queryField}` +
        `&all=${store.searchAll}` +
        `${store.user ? "&userId=" + store.user.id : ""}` +
        `${store.customer ? "&customerId=" + store.customer.id : ""}` +
        `${store.initialDate ? "&initialDate=" + store.initialDate : ""}` +
        `${store.finalDate ? "&finalDate=" + store.finalDate : ""}` +
        `&sort=${store.sort.column}` +
        `&order=${store.sort.ascending ? "ASC" : "DESC"}`
      )
    ),

    userReportTsv: action(() => get(
        `/paymentHistory/user/report/tsv?query=${store.queryField}` +
        `&all=${store.searchAll}` +
        `${store.user ? "&userId=" + store.user.id : ""}` +
        `${store.customer ? "&customerId=" + store.customer.id : ""}` +
        `${store.initialDate ? "&initialDate=" + store.initialDate : ""}` +
        `${store.finalDate ? "&finalDate=" + store.finalDate : ""}` +
        `&sort=${store.sort.column}` +
        `&order=${store.sort.ascending ? "ASC" : "DESC"}`
      )
    ),
  
    check: action(() =>
      get(`/paymentHistory/${store.selected[0].id}`)
        .then(jsonParser)
        .then((data) => {
          data["checked"] = !data["checked"];
          put(`/paymentHistory/${data.id}`, { body: data }).then(jsonParser);
        })
        .catch(e => console.log(e.message ?? e))
    ),

    query: action(() =>
      get(
        `/paymentHistory/advanced?query=${store.queryField}` +
          `${store.user ? "&userId=" + store.user.id : ""}` +
          `${store.customer ? "&customerId=" + store.customer.id : ""}` +
          `${store.initialDate ? "&initialDate=" + store.initialDate : ""}` +
          `${store.finalDate ? "&finalDate=" + store.finalDate : ""}` +
          `&all=${store.searchAll}` +
          `&size=${store.size}` +
          `&page=${store.page}` +
          `&sort=${store.sort.column}` +
          `&order=${store.sort.ascending ? "ASC" : "DESC"}`
      )
      .then(jsonParser)
      .then((data) => {
        store.page === 0
          ? (store.list = data.content)
          : store.list.push.apply(store.list, data.content);
        store.count = data.count;
        })
      .catch(e => console.log(e.message ?? e))
    ),
  };
};
